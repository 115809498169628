import axios from "axios";
import router from "@/router";
const config = require('@/config/config.json');
// Create an Axios instance with custom configuration
const api = axios.create({
    baseURL: config.baseUrl,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

// TODO add working interceptor
api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (window.location.pathname !== '/login') {
            router.push({ path: '/login' });
        }
    }
    return error;
});

export default api;
