import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialog: false,
    timerCardItem: {
      title: '',
      elapsed: 0,
      length: 0,
    },
    timerEnabled: false,
    timerCardItems: [],
    isLoggedIn: null,
  },
  getters: {
    timerCardItems(state) {
      return state.timerCardItems;
    }
  },
  mutations: {
    showDialog(state, timerCardItem) {
      state.timerCardItem = timerCardItem;
      state.dialog = true
    },
    hideDialog(state) {
      state.dialog = false
    },
    toggleDialog(state) {
      // TODO add function to be used everywhere
      // TODO call stop timer on close
      // TODO update dashboard
      state.dialog = !state.dialog
    },
    stopTimer(state) {
      state.timerEnabled = false;
    },
    loadTimerCardItems(state) {
      axios.get('/api/timers')
          .then(res => {
            state.timerCardItems = res.data;
          })
          .catch(err => {
            // console.log(err)
          })
    },

  },
  actions: {
    toggleTimer(context) {
      if(context.state.timerEnabled) {
        context.commit("stopTimer")
        // TODO make ajax call to update value
      } else {
        context.dispatch("startTimer")
      }
    },
    startTimer(context) {
      context.state.timerEnabled = true;
      context.dispatch("timer");
    },

    timer(context) {
      if (!context.state.timerEnabled) {
        return;
      }
      setTimeout(() => {
        if (context.state.timerEnabled) {
          context.state.timerCardItem.elapsed++;
          // TODO add time formatting
          document.title = '⏱ ' + context.state.timerCardItem.elapsed;

        }
        context.dispatch("timer")
      }, 1000);
    },

  },
  modules: {
  }
})
