import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import axios from '@/axios';
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/layouts/default/Login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.state.isLoggedIn === null) {
    // TODO Change to is logged in call
    const response = await axios.get('/api/timers');
    // console.log(response)
    if (response.hasOwnProperty('status') && response.status !== 401
        // || response.response.hasOwnProperty('status') && response.response.status !== 401
    ) {
      store.state.isLoggedIn = true;
    } else {
      store.state.isLoggedIn = false;
    }
  }

  // console.log(store.state.isLoggedIn)
  if (store.state.isLoggedIn === false && to.name !== 'Login') {
    next({ name: 'Login' });
  }
  next()
})

router.beforeEach(async (to, from, next) => {
  if (store.state.isLoggedIn === true && to.name === 'Login') {
    next({ name: 'Home' });
  }

  next()
});


export default router
