import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Config
const config = require('@/config/config.json');

// Initialize Firebase
import { initializeApp } from "firebase/app";
initializeApp(config.firebase);

Vue.config.productionTip = false
import axios from './plugins/axios'
Vue.use(axios, { baseURL: config.baseUrl })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
